<template>
 <v-card>
    <v-card-title>
       <span>Botones de panico</span>

        <v-divider  class="mx-4" inset vertical></v-divider>


            <v-flex md2 lg2 mx-4 pt-5>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
                    </v-flex>


              
                  <v-btn dark color="success" @click="dialogInicio=true">
                  <v-icon>date_range</v-icon>
                  </v-btn>
            


              <vue-excel-xlsx  class="mx-1" v-show="data_excel.length >0"
                  :data="data_excel"
                  :columns="column_excel"
                  :filename="'Historial_botones'"
                  :sheetname="'hoja1'">
                  <v-btn color="info">
                      <v-icon dark>get_app</v-icon>
                  </v-btn>
                  
                  </vue-excel-xlsx>

                  <v-switch class="ml-5 mt-5"
                      v-model="switchTipos"
                      :label="LabelSwitch"
                    ></v-switch>


              <v-text-field class="mx-5"
                  v-model="search"
                  append-icon="search"
                  label="Busqueda"
                  single-line
                  hide-details
                ></v-text-field>

    </v-card-title>


  <v-data-table
    :headers="headers"
    :items="ArrayBotones"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >


    <template v-slot:[`item.evento`]="{ item }">

         <v-chip :color="getColor(item)" dark @click="editItem(item)" small >
           <span>{{ item.evento }}</span>
         </v-chip>

    </template>

    

      <template v-slot:[`item.fecha`]="{ item }">
                  <span>{{ getDate(item.fecha) }}</span>
       </template>

       <template v-slot:[`item.ubicacion`]="{ item }">
      <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
    </template>


    <template v-slot:[`item.observacion`]="{ item }">
        <v-icon small class="mr-2" color="indigo" @click="editItem(item)">edit</v-icon>
      <span>{{  item.observacion }} </span>
      <span class="caption">- {{ item.editado}}</span>
    </template>

   
    <template v-slot:no-data>
      <v-btn  color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>



   <v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Botones por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>

           <v-flex>

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
   </v-dialog>





    <v-dialog   v-model="dialog"  max-width="700px">

          <v-card>


            <v-toolbar color="fondo1">
            <v-toolbar-title class="white--text">{{ editedItem.evento }}: {{ editedItem.nombre }}  </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialog=false">close</v-icon>
          </v-toolbar>


            <v-card-text>
              <v-container>

                <v-row>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Vehiculo"
                    v-model="editedItem.nombre"
                    disabled
                 ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Placa"
                    v-model="editedItem.placa"
                    disabled
                 ></v-text-field>
                </v-col>

                <!--v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Evento"
                     v-model="editedItem.evento"
                     disabled
                 ></v-text-field>
                </v-col-->

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Fecha"
                    v-model="editedItem.fecha"
                    disabled
                 ></v-text-field>
                </v-col>

                  <v-col cols="12" sm="6" md="6">
                   
                    <input type="radio" :value="true" v-model="editedItem.valido"> Valido
                    <input type="radio" :value="false" v-model="editedItem.valido"> Invalido
 
                 
                </v-col>





                  <v-col cols="12" sm="6" md="12">
                    <v-textarea
                      background-color="grey lighten-2"
                      prepend-icon="description"
                      v-model="editedItem.observacion"
                      label="Observacion"
                      placeholder="aqui ingrese observacion"
                      rows="2"
                    ></v-textarea>
                  </v-col>



                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
            
              
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="primary" dark @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    <v-dialog v-model="dialogAgree" persistent width="500">
            <v-card>
              <v-card-title class="text-h5 fondo1 white--text">
              Modulo Botones de panico
              </v-card-title>

              <v-card-text>
                Recargara eventos de los ultimos 40 segundos y notificara con alerta sonora al detectar evento sin observacion
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  color="primary"  text   @click="dialogAgree = false">
                  Aceptar
                </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    



</v-card>

</template>




<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";


  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
      
        { text: 'Vehiculo', value: 'id_vehiculo.nombre' },
        { text: 'Placa', value: 'id_vehiculo.placa' },
        { text: 'Flota', value: 'id_vehiculo.flota' },
        { text: 'Conductor', value:'id_conductor.nombre_conductor'},
        { text: 'Fecha', value: 'fecha' },
        { text:  'Ubicacion', value:  'ubicacion'},
        { text:  'Evento', value:  'evento'},
        { text:  'Direccion', value:  'direccion'},
        { text:  'Observacion', value:  'observacion'}
      ],
      ArrayBotones: [],
      editedIndex: -1,
      editedItem: {
            id_avl:'',
            nombre: '',
            placa: '',
            flota: '',
            fecha: '',
            evento: '',
            direccion:'',
            observacion:'',
            editado:'',
            valido:true
      },
      defaultItem: {
        id_avl:'',
            nombre: '',
            placa: '',
            flota: '',
            fecha: '',
            evento: '',
            direccion:'',
            observacion:'',
            editado:'',
            valido:true
      },


      data_excel:[],

      column_excel:[ 
                    {label: "Vehiculo",  field: "vehiculo"},
                    {label: "Placa", field: "placa"},
                    {label: "Flota", field: "flota"},
                    {label: "Conductor", field: "conductor"},

                    {label: "Fecha",  field: "fecha"},
                    {label: "Latitud", field: "latitud"},
                    {label: "Longitud", field: "longitud"},
                    {label: "Evento", field: "evento"},
                    {label: "valido", field: "valido"},
                    {label: "Direccion",  field: "direccion"},
                    {label: "Observacion",  field: "observacion"},
                    {label: "editado", field: "editado"}
       ],

       dialogInicio:false,
       id_pais_activo:'',
       PaisesArray:[],

       search:'',

        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),

        switchTipos:true,
        LabelSwitch:'Validos',

        intervalo_ejecucion:null,

        dialogAgree:true


    }),

    computed: {

      
          ...mapState(['usuario', 'token']),
    
    },

    watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },

       switchTipos(val){
          if(val==true){
             this.LabelSwitch='Validos';
             this.ActualizarReporte();
          }else{
               this.LabelSwitch='Invalidos';
               this.ActualizarReporte();
          }
      }

    },

    created () {
        this.fecha_inicio=this.CalcularFechaHoy();
        this.fecha_fin=this.CalcularFechaHoy();

        this.initialize();
    },

    methods: {

        ...mapMutations(['mostrarLoading','ocultarLoading']),

      initialize () {
       this.listarPaises ();
      },

      listarPaises () {

                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'Accediendo a datos'});

                    let me=this;

                    let RespuestaPaises=[];

                      axios.get('paises',  config
                      ).then(function(response){
                        console.log(response);
                        RespuestaPaises=response.data;

                        RespuestaPaises.map(function(x){
                              me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                            });

                        me.id_pais_activo=me.PaisesArray[0].value;

                        me.ocultarLoading();

                        me.listar();
                        
                          
                      }).catch(function(error){
                        console.log(error);
                      });
          },


      listar(){


          clearInterval(this.intervalo_ejecucion);


          this.ArrayResumenes=[];
          this.ArrayBotones=[];
          this.respuesta=[];
          this.data_excel=[];
           
               let config={headers:{ token:this.token}};
                let me=this;
                me.mostrarLoading({titulo:'listando datos'});

                  axios.post('eventos',{ 
                                'id_pais':this.id_pais_activo,
                                'desde':this.fecha_inicio,
                                'hasta':this.fecha_fin,
                                'tipo':'Boton'
                              },
                            config
                            ).then(response=>{
                            
                                console.log(response);
                                 me.respuesta=response.data;

                                 let botonSinObservacion=false;
                                
                                  

                                   me.respuesta.map(function(x){

                                     if(x.valido==me.switchTipos){


                                     
                                          me.data_excel.push({ 
                                                    'vehiculo':x.id_vehiculo.nombre,
                                                    'placa':x.id_vehiculo.placa,
                                                    'flota':x.id_vehiculo.flota,
                                                    'conductor':x.id_conductor.nombre_conductor,
                                                    'fecha':me.getDate(x.fecha),
                                                    'evento':x.evento,
                                                    'valido':x.valido,
                                                    'latitud':x.latitud,
                                                    'longitud':x.longitud,
                                                    'direccion':x.direccion,
                                                    'observacion':x.observacion,
                                                    'editado':x.editado

                                                    }); 


                                                    if(x.observacion=='' || x.observacion==null || x.observacion=='-'){
                                                      botonSinObservacion=true;
                                                    }

                                                    me.ArrayBotones.push(x);

                                           }

                                          });


                                          if(botonSinObservacion==true){
                                             me.playSound();
                                          }



                     if((me.fecha_inicio==me.CalcularFechaHoy())&&(me.fecha_fin==me.CalcularFechaHoy())){

                                   clearInterval(me.intervalo_ejecucion);

                                   console.log('se actualziara cada 40 segundos')

                                  me.intervalo_ejecucion=setInterval(() => {
                                                      me.listar();
                                                    }, 40000);

                         }else{
                            console.log('pagina no se recargara debido a que las fechas no son validas');
                            clearInterval(me.intervalo_ejecucion);
                         }

                                          



                                    me.ocultarLoading();
                                 me.dialogInicio=false;

                         }).catch(e=>{ console.log(e) });

      },


       editItem (item) {

          clearInterval(this.intervalo_ejecucion);

          this.editedItem = Object.assign({}, item);

          this.editedItem.nombre=item.id_vehiculo.nombre;
           this.editedItem.placa=item.id_vehiculo.placa;

         if(this.editedItem.fecha){this.editedItem.fecha=this.getDate(this.editedItem.fecha);}
         this.dialog = true;


       },

        close () {
          this.dialog = false
          this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.listar();
          
        })
      },


        getDate(evaluar) {
        let date = new Date(evaluar),
        year = date.getFullYear(),
        month = (date.getMonth() + 1).toString(),
        formatedMonth = (month.length === 1) ? ("0" + month) : month,
        day = date.getDate().toString(),
        formatedDay = (day.length === 1) ? ("0" + day) : day,
        hour = date.getHours().toString(),
        formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
        minute = date.getMinutes().toString(),
        formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
        second = date.getSeconds().toString(),
        formatedSecond = (second.length === 1) ? ("0" + second) : second;
      return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
      },


       openGoogleMap(item){
        const urlSuffix = item.latitud +','+ item.longitud;
        window.open(
          "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
          "_blank"
        );
     },

      save () {

          if(this.editedItem.observacion!=''){

          
                //edicion de evento para ingreso de comentario
    
                      let me=this;
                      let config={headers:{token:me.token}}; 
                      me.mostrarLoading({titulo:'Accediendo a datos'});
                      let id=this.editedItem._id;


                              axios.put(`eventos/${id}`,
                                { 
                                  'observacion': me.editedItem.observacion,
                                  'editado': me.usuario.nombre_usuario+', a las: '+me.getDate(new Date()),
                                  'valido':me.editedItem.valido
                                },
                                config

                                  ).then(response=>{
                                
                                  console.log(response);

                                  me.listar();
                                  me.close();
                                  
                            }).catch(e=>{
                                  console.log(e)
                            });

            
        }else{
            alert('debes ingresar observacion');
        }


      },


     getColor (item) {
 
        if(item.editado !=''){
                return 'pink';
        }else{
              return 'red';
        }
          
    
      },

      CalcularFechaHoy(){
        let fecha=new Date();
         fecha.toLocaleString('en-US', { timeZone: 'America/Managua'});
        let mes=fecha.getMonth()+1;
        if(mes<10)mes='0'+mes;
        let dia=fecha.getDate();
        if(dia<10)dia='0'+dia;
        let textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

        return(textofecha);
      },

      ActualizarReporte(){

      this.ArrayBotones=[];
      this.data_excel=[];
        let me=this;

        me.respuesta.map(function(x){

                                     if(x.valido==me.switchTipos){


                                     
                                          me.data_excel.push({ 
                                                    'vehiculo':x.id_vehiculo.nombre,
                                                    'placa':x.id_vehiculo.placa,
                                                    'flota':x.id_vehiculo.flota,
                                                    'conductor':x.id_conductor.nombre_conductor,
                                                    'fecha':me.getDate(x.fecha),
                                                    'evento':x.evento,
                                                    'valido':x.valido,
                                                    'latitud':x.latitud,
                                                    'longitud':x.longitud,
                                                    'direccion':x.direccion,
                                                    'observacion':x.observacion,
                                                    'editado':x.editado

                                                    }); 

                                                    me.ArrayBotones.push(x);

                                           }

                                          });
      },


      playSound () {
        const sound = require('@/assets/alerta.wav');
        var audio = new Audio(sound);
        audio.play();
    }



     
    },
     beforeDestroy () {

      clearInterval(this.intervalo_ejecucion);
      
   }
  }
</script>